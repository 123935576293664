import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Input, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { Destroyer } from 'src/app/core/abstract/destroyer';
import { DBTag } from 'src/app/core/models/tags.models';
import { filterTagsByTitle } from 'src/app/shared/components/craft-tags/tags.utils';

@Component({
  selector: 'craft-tags-bulk-create',
  templateUrl: './craft-tags-bulk-create.component.html',
  styleUrls: ['./craft-tags-bulk-create.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CraftTagsBulkCreateComponent),
      multi: true,
    },
  ],
})
export class CraftTagsBulkCreateComponent extends Destroyer implements ControlValueAccessor {
  @Input() public existsTags: readonly DBTag[] = [];
  @Input() public autocompleteTags: readonly DBTag[] = [];
  @Input() public disabled = false;
  @Input() public placeholder = '+ Label';

  @ViewChild('tagInput') public tagInput: ElementRef<HTMLInputElement>;

  public tags: readonly string[] = [];
  public readonly autocompleteTags$: Observable<readonly DBTag[]>;
  public readonly inputChanged$ = new BehaviorSubject<string>('');
  public readonly separatorKeysCodes = [ENTER, COMMA] as const;

  private onChange: (_?: readonly string[]) => void = (_) => {};
  private onTouched: () => void = () => {};

  constructor() {
    super();
    this.autocompleteTags$ = this.inputChanged$.pipe(
      distinctUntilChanged(),
      map((searchText) => {
        const excludeSet = new Set(this.tags.map((t) => t.toLocaleLowerCase()));
        return filterTagsByTitle<DBTag>(this.autocompleteTags, excludeSet, searchText);
      }),
      takeUntil(this.destroy$),
    );
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
    this.inputChanged$.complete();
  }

  public writeValue(tags?: readonly string[]): void {
    this.tags = tags || [];
  }

  public registerOnChange(fn: (_?: readonly string[]) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public add(event: MatChipInputEvent, tagInput: HTMLInputElement): void {
    tagInput.value = '';

    const title = event.value?.trim?.();
    if (title) {
      this.addTag(title);
    }
  }

  public selectAutocompleteTag(event: MatAutocompleteSelectedEvent, tagInput: HTMLInputElement): void {
    tagInput.value = '';

    const title = event.option.value.title;
    if (title) {
      this.addTag(title);
    }
  }

  public addDraft() {
    const title = this.tagInput?.nativeElement?.value?.trim?.();
    if (!title) return;

    this.addTag(title);
    if (this.tagInput.nativeElement) {
      this.tagInput.nativeElement.value = '';
    }
  }

  public remove(tag: string) {
    this.tags = this.tags.filter((t) => t !== tag);
    this.onTouched();
    this.onChange(this.tags);
  }

  public displayName(tag: DBTag): string {
    return tag && tag.title ? tag.title : '';
  }

  public identifyTags(index: number, item?: DBTag): string | number {
    return item?.id || index;
  }

  public isTagExists(title: string) {
    if (!title) return false;
    const tagTitle = title.toLocaleLowerCase();
    return this.tags.map((tag) => tag.toLocaleLowerCase()).includes(tagTitle) || this.existsTags.some((t) => t.title.toLocaleLowerCase() === tagTitle);
  }

  private addTag(title: string) {
    this.onTouched();
    if (!title || this.isTagExists(title)) return;

    this.tags = [...this.tags, title];
    this.onChange(this.tags);
  }
}
